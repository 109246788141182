@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #ccccccb2;
  border-radius: 20px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(207, 220, 246, 1);
  cursor: pointer;
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-family: 'Inter', sans-serif;
  }
}

table,
th,
td {
  border-collapse: collapse;
}

.spanclasses {
  font-weight: 600;
}

.sidebar {
  position: fixed;
  top: 0;
  height: 100vh;
  transition: right 0.3s ease-in-out;
  z-index: 9999;
  border-radius: 12px 12px 0px 0px;
  background: var(--neutral-white, #fff);
  box-shadow: -5px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

body.no-scroll {
  overflow: hidden;
}

.sidebar.open {
  right: 0;
}

.sidebar.close {
  right: -100% !important;
  width: 0%;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

input,
input::placeholder {
  font-size: 12px;
}

.ql-editor {
  min-height: 150px !important;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.ql-snow .ql-formats:not(:last-child) {
  border-right: 1px solid #c7cbd4;
  padding: 0px 10px 0px 0px;
}

/* .overflow-auto::-webkit-scrollbar-vertical {
  width: 5px !important;
}

.overflow-auto::-webkit-scrollbar-horizontal {
  height: 5px !important;
}

.overflow-auto::-webkit-scrollbar-track {
  background: #F8FBF1;
}

.overflow-auto::-webkit-scrollbar-thumb {
  background: #0C604A;
  border-radius: 10px;
}

.overflow-auto::-webkit-scrollbar-thumb:hover {
  background: #d9f1a2;
} */
